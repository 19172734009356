import { useTheme } from '@emotion/react'
import React, { useState } from 'react'
import VisuallyHidden from '../VisuallyHidden'
import {
    StyledAlertBanner,
    StyledAlertActionButton,
    StyledAlertBannerBox,
    StyledAlertActionButtonCross,
    StyledInfoIcon,
    StyledAlertBannerHeader,
    StyledAlertBannerText,
} from './AlertBanner.styled'
import { set as setCookie, get as getCookie } from 'js-cookie'
import Box from '../Box'

export const COOKIE_DISMISS_SEASON_ALERT_BANNER =
    'seasonAfl2023RdyAlertBannerDismised'

export function AlertBanner({
    aflTippingAvailable,
}: {
    aflTippingAvailable: boolean | undefined
}) {
    const theme = useTheme()

    const [isVisible, setIsVisible] = useState(
        aflTippingAvailable ? true : false,
    )

    function dismissAlert() {
        aflTippingAvailable &&
            setCookie(COOKIE_DISMISS_SEASON_ALERT_BANNER, 'true')
        setIsVisible(false)
    }

    const shouldHideAlertBanner = getCookie(COOKIE_DISMISS_SEASON_ALERT_BANNER)

    if (!isVisible || shouldHideAlertBanner) {
        return null
    }

    return (
        <Box backgroundColor={'bayBlue'}>
            <StyledAlertBanner>
                <StyledAlertBannerBox padding={'md'}>
                    <StyledAlertBannerHeader>
                        <StyledInfoIcon />
                        {aflTippingAvailable && <h3>What's New</h3>}
                    </StyledAlertBannerHeader>
                    {aflTippingAvailable && (
                        <StyledAlertBannerText>
                            AFL season is back! Register for AFL Tipping below
                            to be eligible for prizes.
                        </StyledAlertBannerText>
                    )}
                </StyledAlertBannerBox>
                <StyledAlertActionButton onClick={dismissAlert}>
                    <VisuallyHidden>Close</VisuallyHidden>
                    <StyledAlertActionButtonCross fill={theme.colors.white} />
                </StyledAlertActionButton>
            </StyledAlertBanner>
        </Box>
    )
}
